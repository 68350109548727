import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Flex, Spinner } from '@chakra-ui/react';

export default function Pane({
  header,
  footer,
  children,
  childrenContainerProps,
  placeholder,
  isLoading,
  ...props
}) {
  const containerProps = childrenContainerProps ?? {
    overflowY: 'auto',
    flex: 1,
    paddingBottom: 3,
    marginBottom: 5,
  };

  return (
    <Box height="full" minWidth={310} borderRadius={10} boxShadow="md" overflow="hidden" {...props}>
      <Flex height="full" flexDirection="column">
        {header}
        <Box {...containerProps}>
          <>
            {!isLoading ? (
              children || placeholder
            ) : (
              <Center mt={4}>
                <Spinner color="blue.500" />
              </Center>
            )}
          </>
        </Box>
        {!isLoading && footer}
      </Flex>
    </Box>
  );
}

Pane.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  placeholder: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isLoading: PropTypes.bool,
  childrenContainerProps: PropTypes.object,
};
